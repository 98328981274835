import { useMemo, useCallback, useState } from 'react';
import { message, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import Logout from '@system-global/assets/icons/logout.svg?react';
import { useStore } from '@system-global/store';
import styles from './styles.module.scss';

const Header = () => {
  const intl = useIntl();
  const doLogout = useStore(state => state.global_doLogout);
  const userInfo = useStore(state => state.user_userInfo);
  const [visible, setVisible] = useState(false);

  const onLogout = useCallback(() => {
    doLogout(undefined, true).finally(() => {
      message.success(intl.formatMessage({ id: '登出成功' }));
    });
  }, [doLogout, intl]);

  const content = useMemo(() => {
    return (
      <div className={styles.content}>
        <div className={styles.userInfo}>
          <Avatar className={styles.userAvatar} size="large" icon={<UserOutlined />} />
          <div className={styles.userEmail}>{userInfo?.email}</div>
        </div>
        <div className={styles.actions}>
          <div className={styles.menuBtn} onClick={onLogout}>
            <Logout className={styles.menuBtnIcon} />
            <FormattedMessage id="登出" />
          </div>
        </div>
      </div>
    );
  }, [onLogout, userInfo]);

  return (
    <>
      <div className={styles.header}>
        <span className={styles.site}>LeadsNavi</span>
        <Avatar className={styles.userAvatar} size="small" icon={<UserOutlined />} onClick={() => setVisible(true)} />
      </div>
      {visible && (
        <div className={styles.dropDown} onClick={() => setVisible(false)}>
          <div className={styles.dropDownContent} onClick={e => e.stopPropagation()}>
            {content}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
