import { useCallback } from 'react';
import { message } from 'antd';
import { NoticeType } from 'antd/lib/message/interface';
import styles from './style.module.scss';

export function useToast() {
  const showToast = useCallback((content: string, type: NoticeType = 'success') => {
    message.open({
      className: styles.toast,
      type,
      content,
      // duration: 1000,
    });
  }, []);

  return { showToast };
}
