import { useState, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useKeepNavigate } from '@system-global/hooks/use-keep-navigate';
import { Modal } from '@ui/components/modal';
import { useStore } from '@system-global/store';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { useIsVerified } from '@system-global/hooks/use-is-verified';
import Icon1 from '@system-global/assets/icons/install-tip-1.svg?react';
import Icon2 from '@system-global/assets/icons/install-tip-2.svg?react';
import styles from './style.module.scss';

const showPaths = [ROUTE_URLS.allVisitors, ROUTE_URLS.newVisitors, ROUTE_URLS.visitorHistory];

export const InstallSdkModal = () => {
  const navigate = useKeepNavigate();
  const location = useLocation();
  const isVerified = useIsVerified();
  const [open, setOpen] = useState(false);
  const userInfo = useStore(state => state.user_userInfo);
  const intl = useIntl();

  const isSampleModalShow = useMemo(() => {
    if (location.pathname === ROUTE_URLS.allVisitors) {
      const key = `${userInfo?.id}_allVisitors`;
      return window?.localStorage.getItem(key) !== '1';
    }

    if (location.pathname === ROUTE_URLS.newVisitors) {
      const key = `${userInfo?.id}_newVisitors`;
      return window?.localStorage.getItem(key) !== '1';
    }

    return false;
  }, [location, userInfo]);

  useEffect(() => {
    if (!isSampleModalShow && showPaths.includes(location.pathname)) {
      setOpen(true);
    }
  }, [location, isVerified, userInfo, isSampleModalShow]);

  if (isVerified || !userInfo || !showPaths.includes(location.pathname)) {
    return null;
  }

  return (
    <Modal
      open={open}
      centered
      closable={false}
      maskClosable={false}
      wrapClassName={styles.installModal}
      okText={intl.formatMessage({ id: '现在安装' })}
      cancelText={intl.formatMessage({ id: '关闭' })}
      title={
        <>
          <div className={styles.title}>
            <FormattedMessage id="安装引导弹窗标题" />
          </div>
          <div className={styles.subTitle}>
            <FormattedMessage id="安装引导弹窗副标题" />
          </div>
        </>
      }
      onOk={() => {
        navigate(ROUTE_URLS.installTracker);
        setOpen(false);
      }}
      onCancel={() => setOpen(false)}
      onClose={() => setOpen(false)}
    >
      <div className={styles.content}>
        <div className={styles.desc}>
          <FormattedMessage id="安装引导弹窗内容" />
        </div>
        <div className={styles.panel}>
          <div className={styles.row}>
            <Icon1 className={styles.icon} />
            <FormattedMessage id="安装引导弹窗描述1" />
          </div>
          <div className={styles.row}>
            <Icon2 className={styles.icon} />
            <FormattedMessage id="安装引导弹窗描述2" />
          </div>
        </div>
      </div>
    </Modal>
  );
};
