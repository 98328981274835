import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form } from 'antd';
import classnames from 'classnames';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { useNavigate } from 'react-router-dom';
import LoginContainer from '@system-global/components/login-and-register-form/login-container';
import LoginStyle from '@system-global/layout/sub-layouts/login-root/styles.module.scss';
import IconInfo from '@system-global/assets/icons/icon_Info.svg?react';
import styles from './styles.module.scss';

interface Props {
  email: string;
}
const LoginTip: React.FC<Props> = props => {
  const nav = useNavigate();
  const intl = useIntl();

  const goToLogin = useCallback(() => {
    nav(ROUTE_URLS.login, { state: { email: props.email } });
  }, [nav, props.email]);

  return (
    <div className={classnames(styles.loginTip, LoginStyle.loginRoot)}>
      <LoginContainer>
        <div className={styles.tipContent}>
          <div className={styles.title}>
            <IconInfo className={styles.icon} />
            <FormattedMessage id="邮箱账号已被注册" />
          </div>
          <div className={styles.subTitle}>
            <FormattedMessage id="使用密码登录提示" />
          </div>
          <Form layout="vertical">
            <Form.Item label={intl.formatMessage({ id: '邮箱' }).toUpperCase()}>
              <div className={styles.emailInput}>{props.email || ''}</div>
            </Form.Item>
          </Form>
          <Button type="primary" size="large" className={styles.confirmBtn} onClick={goToLogin}>
            <FormattedMessage id="登录" />
          </Button>
        </div>
      </LoginContainer>
    </div>
  );
};

export default LoginTip;
