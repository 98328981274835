import React, { useState } from 'react';
import styles from './styles.module.scss';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useKeepNavigate } from '@system-global/hooks/use-keep-navigate';
import { Form, Button, App, Divider } from 'antd';
import UserAgreementChecker from '@system-global/components/login-and-register-form/user-agreement-checker';
import EmailFormItem from '@system-global/components/login-and-register-form/email-form-item';
import RequiredFormItem from '@system-global/components/login-and-register-form/pwd-form-item-package/required-form-item';
import { emailPwdLoginPost } from '@api/system-global/__generate__/default/UserLoginService';
import { GoogleLoginBtn } from '@system-global/components/google-login-button';

interface ICompProps {
  onLoginSucceed: (token: string) => void;
}

export interface ILoginFieldType {
  email: string;
  pwd: string;
}

const LoginForm: React.FC<ICompProps> = ({ onLoginSucceed }) => {
  const intl = useIntl();
  const nav = useKeepNavigate();
  const location = useLocation();
  const { message } = App.useApp();
  const [form] = Form.useForm<ILoginFieldType>();

  const [agreementChecked, setAgreementChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validateDebounce, setValidateDebounce] = useState(500);

  const onSignUp = () => {
    nav(ROUTE_URLS.register);
  };

  const onSubmit = () => {
    setValidateDebounce(0);
    form
      .validateFields()
      .then(res => {
        if (!agreementChecked) {
          message.error(intl.formatMessage({ id: '请阅读并同意隐私协议' }));
          return;
        }
        setLoading(true);
        const { email, pwd } = res;
        emailPwdLoginPost({ email, pwd })
          .then(v => {
            if (v?.data?.data?.token && v?.data?.code === 0) {
              onLoginSucceed(v.data.data.token);
              return;
            }
          })
          .finally(() => {
            setLoading(false);
            setValidateDebounce(500);
          });
      })
      .catch(() => {
        setLoading(false);
        setValidateDebounce(500);
      });
  };

  const PwdLabel = (
    <div className={styles.pwdLabel}>
      <span>{intl.formatMessage({ id: '密码' }).toUpperCase()}</span>
      <span className={styles.link} onClick={() => nav(ROUTE_URLS.resetPwd)}>
        <FormattedMessage id="忘记密码吗" />
      </span>
    </div>
  );

  return (
    <div className={styles.loginFormContainer}>
      <p className={styles.title} id="loginPageTitle">
        <FormattedMessage id="登录" />
      </p>
      <p className={styles.subTitle}>
        <span>
          <FormattedMessage id="还没账号吗" />
        </span>
        <span className={styles.link} onClick={onSignUp} id="loginPageRegisterBtn">
          <FormattedMessage id="注册" />
        </span>
      </p>
      <div style={{ paddingTop: 32 }}>
        <GoogleLoginBtn type="login" />
        <Divider>OR</Divider>
      </div>
      <Form
        name="passwordLogin"
        form={form}
        layout="vertical"
        className={styles.loginForm}
        initialValues={{ email: location?.state?.email || '' }}
      >
        <EmailFormItem scene="login" validateDebounce={validateDebounce} onPressEnter={onSubmit} showResend />
        <RequiredFormItem
          name="pwd"
          type="pwd"
          label={PwdLabel}
          className={styles.pwdLabelWrapper}
          onPressEnter={onSubmit}
          field={intl.formatMessage({ id: '密码' })}
        />
      </Form>
      <Button
        id="loginPageSubmitBtn"
        type="primary"
        size="large"
        className={styles.confirmBtn}
        onClick={onSubmit}
        loading={loading}
      >
        <FormattedMessage id="登录" />
      </Button>
      <UserAgreementChecker checked={agreementChecked} setChecked={setAgreementChecked} scene="login" />
    </div>
  );
};

export default LoginForm;
