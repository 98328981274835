import React, { useMemo } from 'react';
import { Modal as AntModal, ModalProps, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { TongyongGuanbiXian } from '@sirius/icons';
import cn from 'classnames';

import styles from './index.module.scss';

export interface CustomeProps extends ModalProps {
  bodyClassName?: string;
}
export const Modal: React.FC<CustomeProps> = props => {
  const { closeIcon, bodyClassName } = props;

  const curCloseIcon = useMemo(() => {
    return closeIcon ? (
      closeIcon
    ) : (
      <span className={styles['close-icon']}>
        <TongyongGuanbiXian />
      </span>
    );
  }, [closeIcon]);

  return (
    <AntModal
      {...props}
      rootClassName={cn(styles['modal'], props.rootClassName)}
      closeIcon={curCloseIcon}
      footer={props.footer ? props.footer : null}
    >
      <div className={cn(styles['body'], bodyClassName)}>{props.children}</div>

      {/* footer按钮区 */}
      {!props.footer ? (
        <div className={styles['footer']}>
          <Button
            type="primary"
            size="large"
            {...props.okButtonProps}
            onClick={e => {
              props.onOk && props.onOk(e as any);
            }}
          >
            {props.okText || <FormattedMessage id="确定" />}
          </Button>
          <Button
            size="large"
            onClick={e => {
              props.onCancel && props.onCancel(e as any);
            }}
          >
            {props.cancelText || <FormattedMessage id="取消" />}
          </Button>
        </div>
      ) : null}
    </AntModal>
  );
};
