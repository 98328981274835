import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import Symbol from './assets/symbol.png';
import BgIcon from './assets/bg-icon.svg?react';

interface IProps {
  coverImage: string;
  containerStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  bgStyle?: React.CSSProperties;
  className?: string;
  icon?: React.ReactNode;
}

const LoginCover: React.FC<IProps> = ({ coverImage, icon, className, containerStyle, bgStyle = {}, contentStyle }) => {
  return (
    <div className={classnames(className, styles.loginCover)} style={containerStyle}>
      <div className={styles.iconWrapper}>{icon ? icon : <BgIcon />}</div>
      <div className={styles.rightContent} style={contentStyle}>
        <img alt="login cover" src={coverImage} style={bgStyle} />
        <div
          className={styles.symbol}
          style={{
            backgroundImage: `url(${Symbol})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        ></div>
        <div className={styles.intro}>
          <FormattedMessage id="_登录介绍" />
        </div>
        <div className={styles.endWrapper}>
          <div className={styles.end}></div>
        </div>
      </div>
    </div>
  );
};

export default LoginCover;
