import React from 'react';
import { Input } from 'antd';
import { TongyongGuanbiXian, TongyongXianshi, TongyongYincang } from '@sirius/icons';
import styles from './styles.module.scss';

interface ICompProps {
  value?: string;
  onChange?: (value: string) => void;
  onPressEnter?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const PasswordInput: React.FC<ICompProps> = ({ value, onChange, onPressEnter, onFocus, onBlur }) => {
  return (
    <Input.Password
      value={value}
      className={styles.pwdInput}
      onChange={e => onChange && onChange(e.target.value)}
      variant="filled"
      size="large"
      allowClear={{ clearIcon: <TongyongGuanbiXian style={{ fontSize: 16 }} /> }}
      autoComplete="new-password"
      maxLength={20}
      onPressEnter={onPressEnter}
      onFocus={onFocus}
      onBlur={onBlur}
      iconRender={visible => (visible ? <TongyongXianshi /> : <TongyongYincang />)}
    />
  );
};

export default PasswordInput;
