import React, { useState } from 'react';
import { Form, Input, FormItemProps, Checkbox, ConfigProvider } from 'antd';
import { useIntl } from 'react-intl';
import { TongyongGuanbiXian } from '@sirius/icons';
import styles from './styles.module.scss';

interface IComProps extends FormItemProps {
  defaultChecked?: boolean;
  disabled?: boolean;
  onPressEnter?: () => void;
}

interface IFieldType {
  invitationCode: string;
}

const InvitationCodeItem: React.FC<IComProps> = ({ disabled, onPressEnter }) => {
  const intl = useIntl();
  const baseLabel = intl.formatMessage({ id: '邀请码' }).toUpperCase();
  const [show, setShow] = useState(false);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBorder: '#C0C8D6',
            colorPrimary: '#8A33AD',
            colorPrimaryHover: '#8A33AD',
          },
        }}
      >
        <Checkbox className={styles.checkbox} checked={show} onChange={e => setShow(e?.target?.checked)}>
          <span className={styles.tip}>{intl.formatMessage({ id: '我有邀请码' })}</span>
        </Checkbox>
      </ConfigProvider>
      {show && (
        <Form.Item<IFieldType>
          name="invitationCode"
          label={baseLabel}
          // validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: '邀请码不能为空' }),
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{0,32}$/,
              message: intl.formatMessage({ id: '邀请码错误' }),
            },
          ]}
        >
          <Input
            size="large"
            variant="filled"
            disabled={disabled}
            onPressEnter={onPressEnter}
            placeholder={intl.formatMessage({ id: '邀请码placeholder' })}
            allowClear={{ clearIcon: <TongyongGuanbiXian style={{ fontSize: 16 }} /> }}
          />
        </Form.Item>
      )}
    </>
  );
};

export default InvitationCodeItem;
