import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useStore } from '@system-global/store';
import MaskLoading from '@ui/components/mask-loading';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { useLnNavigate } from '@system-global/hooks/use-ln-navigate';
import ErrorIcon from '@system-global/assets/icons/res_error.svg?react';
import { quickCodeLoginPost } from '@api/system-global/__generate__/default/UserLoginService';
import styles from './style.module.scss';

const QuickLogin = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(false);
  const nav = useLnNavigate(false);
  const doLoginSucceed = useStore(state => state.login_doLoginSucceed);
  const user_fetchUserInfo = useStore(state => state.user_fetchUserInfo);

  useEffect(() => {
    const code = searchParams.get('code');
    if (!code) {
      setError(true);
      return;
    }

    quickCodeLoginPost({ quickCode: code })
      .then(res => {
        if (!res?.data?.data?.token) {
          setError(true);
          return;
        }
        doLoginSucceed(res.data.data.token);
        setTimeout(() => {
          user_fetchUserInfo({ refresh: true });
          nav(ROUTE_URLS.installTracker);
        }, 500);
      })
      .catch(() => {
        setError(true);
      });
  }, [searchParams, nav, doLoginSucceed, user_fetchUserInfo]);

  if (error) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.error}>
          <ErrorIcon />
          <p>
            <FormattedMessage id="Token不合法或者已过期" />
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <MaskLoading />
    </div>
  );
};

export default QuickLogin;
