import React from 'react';
import { Layout, Flex } from 'antd';
import classnames from 'classnames';
import { UpgradeMiniTip } from '@feature/components/visitor-list/upgrade-reminder';
import styles from './style.module.scss';
import { useStore } from '@system-global/store';

import UserAvatarPopover from '@system-global/components/user-avatar-popover';
import AppLogo from '@system-global/components/app-logo';

interface Props {
  className?: string;
}

const AppHeader: React.FC<Props> = (props: Props) => {
  const { className = '' } = props;
  const userInfo = useStore(state => state.user_userInfo);

  return (
    <Layout.Header className={classnames(className, styles.header)}>
      <AppLogo />
      <Flex gap={16} align="center">
        {!userInfo?.userOrderStatusResp?.officialUser && (
          <UpgradeMiniTip trialDaysLeft={userInfo?.userOrderStatusResp.trialDaysLeft} />
        )}
        <UserAvatarPopover />
      </Flex>
    </Layout.Header>
  );
};

export default AppHeader;
