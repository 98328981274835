import { useSearchParams } from 'react-router-dom';
import { NavigateFunction } from 'react-router-dom';

// 如果 url 中有 redirectUrl 这个参数（或者对应的 key），则使用 onRedirect 时会带着 redirectUrl 进行跳转，或者直接跳转到 redirectUrl
export const useRedirect = (navFn?: NavigateFunction, key = 'redirectUrl') => {
  const [searchParams] = useSearchParams();
  const redirectParams = searchParams.get(key);

  const redirectUrl = redirectParams && redirectParams.startsWith('/') ? decodeURIComponent(redirectParams) : '';

  const onRedirect = (url: string, { append }: { append: boolean }) => {
    if (!navFn) {
      return;
    }
    if (redirectUrl) {
      navFn(append ? `${url}?${key}=${redirectUrl}` : redirectUrl);
    } else {
      navFn(url);
    }
  };

  return { redirectUrl, onRedirect };
};
