import { useState, useCallback } from 'react';
import { Form } from 'antd';
import { bindSitePost } from '@api/system-global/__generate__/default/SiteBizService';
import { useToast } from '../../../components/toast';

export function useBindForm(callBack?: () => void) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { showToast } = useToast();

  const submit = useCallback(async () => {
    // 请输入正确的邮箱
    form
      .validateFields()
      .then(async formData => {
        try {
          setLoading(true);
          const res = await bindSitePost({
            ...formData,
          });

          if (res?.data?.success) {
            callBack?.();
          }
        } finally {
          setLoading(false);
        }
      })
      .catch(err => {
        const errorMessage = err?.errorFields?.[0]?.errors?.[0];
        if (errorMessage) {
          showToast(errorMessage, 'error');
        }
      });
  }, [form, callBack, showToast]);

  return { loading, form, submit };
}
