/** Hubspot OAuth相关 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** Hubspot OAuth相关 获取Hubspot OAuth认证地址 */
export async function getOAuthAddressGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultHubspotOAuthAddressResp>({
    url: `/api/biz/hubspot/connection/address`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** Hubspot OAuth相关 断开Hubspot连接 */
export async function disconnectPost(payload: model.IApiDisconnectHubspotReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/biz/hubspot/connection/disconnect`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** Hubspot OAuth相关 获取Hubspot连接信息 */
export async function getHubspotConnectionGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultHubspotConnectionResp>({
    url: `/api/biz/hubspot/connection/info`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** Hubspot OAuth相关 查询匹配用户（User Matching） */
export async function getUserMatchingGet(
  payload: {
    hubspotUserId?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultUserMatchingResp>({
    url: `/api/biz/hubspot/connection/user/matching`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** Hubspot OAuth相关 设置匹配用户（User Matching） */
export async function updateUserMatchingPost(
  payload: model.IApiUpdateHubspotUserMatchingReq,
  customParams?: IAxiosCustomParams
) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/biz/hubspot/connection/user/matching`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
