import { useState, useEffect } from 'react';
import { Form, Button, Input, Drawer } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStore } from '@system-global/store';
import { TongyongGuanbiXian } from '@sirius/icons';
import SuccessIcon from '@system-global/assets/icons/success2.svg?react';
import GuideEmailPreview from '@system-global/components/guide-email-preview';
import { bindSiteSendOnlyPost } from '@api/system-global/__generate__/default/TutorialService';
import { useSendEmail } from '../../hooks/useSendEmail';
import styles from './styles.module.scss';

interface Props {
  onClose?: () => void;
}
const SendEmail = (props: Props) => {
  const { onClose } = props;
  const [showPreview, setShowPreview] = useState(false);
  const userEmail = useStore(state => state?.user_userInfo?.email);
  const intl = useIntl();
  const { loading, form, submit, skip } = useSendEmail(onClose);

  useEffect(() => {
    bindSiteSendOnlyPost({ receiverType: 'SELF_ONLY' });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.icon}>
          <SuccessIcon />
        </div>
        <div className={styles.title}>
          <FormattedMessage id="H5发送邮件标题" />
        </div>
        <div className={styles.desc}>
          <FormattedMessage
            id="H5发送邮件描述"
            values={{ email: <span className={styles.email}>{userEmail || ''}</span> }}
          />
        </div>
      </div>

      <div className={styles.sendForm}>
        <div className={styles.sendFormTitle}>
          <FormattedMessage id="H5发送邮件表单标题" />
        </div>
        <div className={styles.formPanel}>
          <div className={styles.formDesc}>
            <FormattedMessage id="H5发送邮件表单描述" />
          </div>
          <Form form={form}>
            <Form.Item name="colleagueEmail" noStyle label="" rules={[{ required: true, type: 'email' }]}>
              <Input
                className={styles.input}
                size="large"
                placeholder={intl.formatMessage({ id: 'H5发送邮件邮箱placeholder' })}
                allowClear={{ clearIcon: <TongyongGuanbiXian style={{ fontSize: 16 }} /> }}
              />
            </Form.Item>
          </Form>
        </div>
      </div>

      <div className={styles.link}>
        <span className={styles.linkBtn} onClick={() => setShowPreview(true)}>
          <FormattedMessage id="H5发送邮件预览" />
        </span>
      </div>
      <div className={styles.operation}>
        <Button className={styles.sendbtn} type="primary" block disabled={loading} onClick={submit}>
          <FormattedMessage id="H5发送邮件提交" />
        </Button>
        <Button className={styles.btn} block disabled={loading} onClick={skip}>
          <FormattedMessage id="H5发送邮件跳过" />
        </Button>
      </div>

      <Drawer
        open={showPreview}
        width={'100%'}
        styles={{
          body: { padding: 0, position: 'relative' },
        }}
        onClose={() => setShowPreview(false)}
      >
        <GuideEmailPreview />
      </Drawer>
    </div>
  );
};

export default SendEmail;
