/** 站点相关 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 站点相关 绑定站点 */
export async function bindSitePost(payload: model.IApiBindSiteReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultSiteResp>({
    url: `/api/biz/site/bindSite`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 站点相关 轮询检查验证状态 */
export async function checkVerifyStatusGet(
  payload: {
    siteId: string;
    website: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultCheckVerifyStatusResp>({
    url: `/api/biz/site/checkVerifyStatus`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 站点相关 修改站点信息 */
export async function editSitePost(payload: model.IApiEditSiteReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultSiteResp>({
    url: `/api/biz/site/editSite`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 站点相关 获取全部站点 */
export async function getPreVerifySitesGet(
  payload: {
    statistics?: boolean;
    verifyStatus?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultSiteListResp>({
    url: `/api/biz/site/getPreVerifySites`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
