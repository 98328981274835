import React from 'react';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { onValidatePwd } from '@system-global/utils/validator';
import PasswordInput from '../password-input';

interface ICompProps {
  label?: string;
  name?: string;
  onPressEnter?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

type IKey = ICompProps['name'] extends string ? ICompProps['name'] : never;

type IFieldType = Record<IKey, string>;

const PasswordFormItem: React.FC<ICompProps> = ({ label, name, onPressEnter, onFocus, onBlur }) => {
  const intl = useIntl();

  return (
    <Form.Item<IFieldType>
      name={name || 'pwd'}
      label={label ? label.toUpperCase() : intl.formatMessage({ id: '密码' }).toUpperCase()}
      rules={[
        {
          validator(_, value) {
            return onValidatePwd(value);
          },
        },
      ]}
    >
      <PasswordInput onFocus={onFocus} onPressEnter={onPressEnter} onBlur={onBlur} />
    </Form.Item>
  );
};

export default PasswordFormItem;
