import { useState, useEffect } from 'react';
import { useStore } from '@system-global/store';
import { bindSiteSendStateGet } from '@api/system-global/__generate__/default/TutorialService';

export enum InterceptState {
  BindSite = 'BindSite',
  SendEmail = 'SendEmail',
  PCGuideView = 'PCGuideView',
  Loading = 'Loading',
  NotLogin = 'NotLogin',
}
export function useInterceptState() {
  const [state, setState] = useState<InterceptState>(InterceptState.Loading);
  const currentBind = useStore(state => state.user_currentBindSite);
  const userInfo = useStore(state => state.user_userInfo);

  useEffect(() => {
    if (!userInfo) {
      setState(InterceptState.NotLogin);
      return;
    }
    if (!currentBind?.siteId) {
      setState(InterceptState.BindSite);
      return;
    }
    bindSiteSendStateGet().then(res => {
      if (res.data?.data?.send) {
        setState(InterceptState.PCGuideView);
      } else {
        setState(InterceptState.SendEmail);
      }
    });
  }, [currentBind, userInfo]);

  return { state, setState };
}
