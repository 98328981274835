import { useEffect, useRef, useState } from 'react';
import { bindSitePreviewEmailGet } from '@api/system-global/__generate__/default/TutorialService';
import styles from './styles.module.scss';

interface GuideEmailPreviewProps {
  skipTitle?: boolean;
}
const GuideEmailPreview = (props: GuideEmailPreviewProps) => {
  const { skipTitle } = props;
  const [emailContent, setEmailContent] = useState<string>('');
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe || !iframe.contentWindow) return;
    iframe.contentWindow.document.documentElement.innerHTML = emailContent;
    const mask = iframe.contentWindow.document.createElement('div');
    mask.setAttribute('style', 'position: fixed; top: 0; left: 0; width: 100%; height: 100%;  z-index: 9999;');
    iframe.contentWindow.document.querySelector('body')?.appendChild(mask);

    if (skipTitle) {
      const content = iframe.contentWindow.document.querySelector('.wrapper > div.content');
      const title = iframe.contentWindow.document.querySelector('.wrapper > div.title');
      const logo = iframe.contentWindow.document.querySelector('.wrapper > div.logo');
      title?.parentNode?.removeChild(title);
      logo?.parentNode?.removeChild(logo);
      content?.scrollIntoView({ behavior: 'instant' });
      setTimeout(() => {
        iframe?.contentWindow?.scrollTo(0, iframe.contentWindow.scrollY - 10);
      }, 100);
    }
  }, [emailContent, skipTitle]);

  useEffect(() => {
    bindSitePreviewEmailGet().then(res => {
      setEmailContent(res?.data?.data?.content || '');
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <iframe className={styles.iframe} ref={iframeRef} title="LeadsNavi"></iframe>
    </div>
  );
};

export default GuideEmailPreview;
