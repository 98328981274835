import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import LoginCoverBg from '@system-global/assets/images/login-cover.png';
import LoginCoverBgWithLogo from '@system-global/assets/images/login-cover-logo.png';
import LoginCover from '@ui/components/login-cover';
import AppLogo from '@system-global/components/app-logo';

interface ICompProps {
  children: React.ReactElement;
}

const LoginContainer: React.FC<ICompProps> = ({ children }) => {
  const toUrl = useMemo(() => {
    if (window.location.host !== import.meta.env.SYSTEM_GLOBAL_HOST) {
      return import.meta.env.VITE_WEBSITE_URL;
    }
    // 系统内部登录页  logo不跳转
    return '';
  }, []);

  return (
    <div className={styles.loginContainer}>
      {toUrl ? <AppLogo className={styles.logo} style={{ width: 130, height: 24 }} to={toUrl} /> : null}
      <div className={styles.left} style={{ paddingTop: toUrl ? 60 : 0 }}>
        {children}
      </div>
      <LoginCover
        className={styles.cover}
        coverImage={toUrl ? LoginCoverBg : LoginCoverBgWithLogo}
        containerStyle={{ flexBasis: 853 }}
        contentStyle={{ width: '82.6%' }}
        bgStyle={{ width: '81.8%', marginBottom: 16 }}
      />
    </div>
  );
};

export default LoginContainer;
