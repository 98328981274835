import { StateCreator } from 'zustand';
import { fieldsGet, ownersGet, fieldMappingGet } from '@api/system-global/__generate__/default/HubSpotService';
import { getHubspotConnectionGet } from '@api/system-global/__generate__/default/HubspotBizConnectionService';
import {
  IApiHubSpotFieldMappingItem,
  IApiHubSpotOwner,
  IApiHubSpotFieldMappingPair,
  IApiHubspotConnectionResp,
} from '@api/system-global/__generate__/default/models';

export interface IHubspotStateSlice {
  hubspot_connectionInfo: IApiHubspotConnectionResp | null;
  hubspot_companyFieldMapList: IApiHubSpotFieldMappingPair[];
  hubspot_contactFieldMapList: IApiHubSpotFieldMappingPair[];
  hubspot_fieldSet: {
    companySystemFields: IApiHubSpotFieldMappingItem[];
    companyHubSpotFields: IApiHubSpotFieldMappingItem[];
    contactHubSpotFields: IApiHubSpotFieldMappingItem[];
    contactSystemFields: IApiHubSpotFieldMappingItem[];
  };
  hubspot_accountList: IApiHubSpotOwner[];

  hubspot_setConnectionInfo: (state: IApiHubspotConnectionResp | null) => void;
  hubspot_fetchFields: () => Promise<void>;
  hubspot_fetchAccounts: () => Promise<void>;
  hubspot_fetchFieldMap: () => Promise<void>;
  hubspot_fetchConnectionInfo: () => Promise<void>;
}

let fetchFieldsLock = false;
export const createHubspotStateSlice: StateCreator<IHubspotStateSlice> = set => {
  return {
    hubspot_connectionInfo: null,
    hubspot_companyFieldMapList: [],
    hubspot_contactFieldMapList: [],
    hubspot_fieldSet: {
      companySystemFields: [],
      companyHubSpotFields: [],
      contactHubSpotFields: [],
      contactSystemFields: [],
    },
    hubspot_accountList: [],
    hubspot_setConnectionInfo: (state: IApiHubspotConnectionResp | null) => {
      set({ hubspot_connectionInfo: state });
    },
    hubspot_fetchConnectionInfo: async () => {
      const res = await getHubspotConnectionGet();
      const data = res?.data?.data || null;
      set({ hubspot_connectionInfo: data });
    },
    hubspot_fetchFields: async () => {
      if (fetchFieldsLock) return;
      fetchFieldsLock = true;
      try {
        const res = await fieldsGet();
        const data = res?.data?.data || {};
        set({
          hubspot_fieldSet: {
            companySystemFields: data.companySystemFields || [],
            companyHubSpotFields: data.companyHubSpotFields || [],
            contactHubSpotFields: data.contactHubSpotFields || [],
            contactSystemFields: data.contactSystemFields || [],
          },
        });
      } catch {
        fetchFieldsLock = false;
      }
    },
    hubspot_fetchAccounts: async () => {
      const res = await ownersGet();
      const hubspot_accountList = res?.data?.data?.list || [];
      set({ hubspot_accountList });
    },
    hubspot_fetchFieldMap: async () => {
      const res = await fieldMappingGet();
      const maps = res?.data?.data || {};
      set({
        hubspot_companyFieldMapList: maps?.companyConfig || [],
        hubspot_contactFieldMapList: maps?.contactConfig || [],
      });
    },
  };
};
