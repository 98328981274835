import { Outlet, useMatches } from 'react-router-dom';
import styles from './style.module.scss';
import { Layout } from 'antd';
import AppHeader from '@system-global/components/app-header';
import SettingRootSider from './components/setting-root-sider';
import { IRouteConfig } from '@system-global/config/types';
import { Suspense } from 'react';

export function SettingRoot() {
  const matches = useMatches();
  const hideAside = matches.some(v => (v.handle as IRouteConfig)?.hideAside);
  const hideHeader = matches.some(v => (v.handle as IRouteConfig)?.hideHeader);
  const customLayout = matches.some(v => (v.handle as IRouteConfig)?.customLayout);

  const defaultMainStyle = {
    padding: '28px 24px 0 28px',
    marginRight: 4,
  };

  const defaultContentStyle = {
    margin: '0 auto',
    width: '100%',
    maxWidth: 1660,
    paddingBottom: 28,
    flex: '1 0 auto',
  };

  return (
    <Layout style={{ height: '100%' }}>
      {!hideHeader ? <AppHeader /> : null}
      <Layout>
        {!hideAside ? <SettingRootSider /> : null}
        <Layout className={customLayout ? '' : styles.content} style={customLayout ? undefined : defaultMainStyle}>
          <Layout.Content style={customLayout ? undefined : defaultContentStyle}>
            <Suspense>
              <Outlet />
            </Suspense>
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default SettingRoot;
