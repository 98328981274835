/** HubSpot相关 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** HubSpot相关 新增字段映射配置 */
export async function addFieldMappingPost(
  payload: {
    objectType?: string;
    pair: model.IApiHubSpotFieldMappingPair;
  },
  customParams?: IAxiosCustomParams
) {
  const data = Object.assign({}, payload.pair);

  const result = await axios.request<model.IApiJsonResultHubSpotFieldMappingPair>({
    url: `/api/biz/hubspot/addFieldMapping/${payload.objectType}`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** HubSpot相关 修改字段映射配置 */
export async function editFieldMappingPost(
  payload: {
    objectType?: string;
    pair: model.IApiHubSpotFieldMappingPair;
  },
  customParams?: IAxiosCustomParams
) {
  const data = Object.assign({}, payload.pair);

  const result = await axios.request<model.IApiJsonResultHubSpotFieldMappingPair>({
    url: `/api/biz/hubspot/editFieldMapping/${payload.objectType}`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** HubSpot相关 获取字段映射配置 */
export async function fieldMappingGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultHubSpotFieldMapping>({
    url: `/api/biz/hubspot/fieldMapping`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** HubSpot相关 获取预置字段 */
export async function fieldsGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultHubSpotFields>({
    url: `/api/biz/hubspot/fields`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** HubSpot相关 获取员工列表 */
export async function ownersGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultHubSpotOwners>({
    url: `/api/biz/hubspot/owners`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** HubSpot相关 分页查询报错日志 */
export async function pageErrorLogPost(payload: model.IApiPageReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultPageRespHubSpotPageErrorLogItemResp>({
    url: `/api/biz/hubspot/pageErrorLog`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** HubSpot相关 删除字段映射配置 */
export async function removeFieldMappingPost(
  payload: {
    objectType?: string;
    req: model.IApiRemoveHubSpotFieldMappingReq;
  },
  customParams?: IAxiosCustomParams
) {
  const data = Object.assign({}, payload.req);

  const result = await axios.request<model.IApiJsonResultstring>({
    url: `/api/biz/hubspot/removeFieldMapping/${payload.objectType}`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** HubSpot相关 新建公司同步 */
export async function createCompanyPost(payload: model.IApiHubSpotCreateCompanyReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultstring>({
    url: `/api/biz/hubspot/sync/createCompany`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** HubSpot相关 新建联系人同步 */
export async function createContactPost(payload: model.IApiHubSpotCreateContactReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultstring>({
    url: `/api/biz/hubspot/sync/createContact`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** HubSpot相关 查询公司绑定信息 */
export async function getCompanyBindInfoGet(
  payload: {
    domain?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultHubSpotCompanyBindInfoResp>({
    url: `/api/biz/hubspot/sync/getCompanyBindInfo`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** HubSpot相关 查询联系人绑定信息 */
export async function getContactBindInfoGet(
  payload: {
    domain?: string;
    email?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultHubSpotContactBindInfoResp>({
    url: `/api/biz/hubspot/sync/getContactBindInfo`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** HubSpot相关 解除公司绑定 */
export async function unbindCompanyPost(payload: model.IApiHubSpotUnbindCompanyReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultstring>({
    url: `/api/biz/hubspot/sync/unbindCompany`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** HubSpot相关 解除联系人绑定 */
export async function unbindContactPost(payload: model.IApiHubSpotUnbindContactReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultstring>({
    url: `/api/biz/hubspot/sync/unbindContact`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
