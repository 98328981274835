import React, { useState } from 'react';
import styles from './styles.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, App } from 'antd';
import { forgetPasswordPost } from '@api/system-global/__generate__/default/UserCommonService';
import { useKeepNavigate } from '@system-global/hooks/use-keep-navigate';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import PwdFormItemPackage from '@system-global/components/login-and-register-form/pwd-form-item-package';
import BackToLoginBtn from '@system-global/components/login-and-register-form/back-to-login-btn';

interface IAccountFieldType {
  pwd: string;
}

interface ICompProps {
  cs: string;
  onFail: () => void;
}

const SetPwdForm: React.FC<ICompProps> = ({ cs, onFail }) => {
  const intl = useIntl();
  const nav = useKeepNavigate();
  const { message } = App.useApp();
  const [form] = Form.useForm<IAccountFieldType>();

  const passwordInForm = Form.useWatch('pwd', form);

  const [loading, setLoading] = useState(false);

  const onResetPwd = () => {
    form.validateFields().then(() => {
      setLoading(true);
      const { pwd } = form.getFieldsValue();
      forgetPasswordPost({ cs: encodeURIComponent(cs), newPassword: pwd }, { noErrorHint: true })
        .then(v => {
          if (v.data.code === 0) {
            message.success({
              duration: 1,
              content: intl.formatMessage({ id: '密码修改完成请重新登录' }),
              onClose() {
                nav(ROUTE_URLS.login);
              },
            });
          } else if (v.data.code === 1002) {
            message.error({
              content: intl.formatMessage({ id: '验证邮件已失效请重新发送' }),
              duration: 1,
              onClose() {
                onFail();
              },
            });
          } else {
            message.error(v.data.message);
          }
        })
        .catch(() => {
          message.error(intl.formatMessage({ id: '重置密码失败' }));
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        <FormattedMessage id="重置密码" />
      </p>
      <Form name="form" form={form} layout="vertical" className={styles.loginForm}>
        <PwdFormItemPackage
          value={passwordInForm}
          label={intl.formatMessage({ id: '新密码' })}
          showRepeat
          onPressEnter={onResetPwd}
        />
      </Form>
      <Button type="primary" size="large" className={styles.submitBtn} onClick={onResetPwd} loading={loading}>
        <FormattedMessage id="重置密码" />
      </Button>
      <BackToLoginBtn />
    </div>
  );
};

export default SetPwdForm;
