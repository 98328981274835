import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createLoginSlice, ILoginSlice } from './slices/createLoginSlice';
import { createUserSlice, IUserSlice } from './slices/createUserSlice';
import { createBindSlice, IBindSlice } from './slices/createBindSlice';
import { createEmailBaseDataSlice, IEmailBDSlice } from './slices/createEmailBaseDataSlice';
import { createHubspotStateSlice, IHubspotStateSlice } from './slices/createHubspotState';
import { LANGUAGES } from '../lang';

interface IGlobalSlice {
  global_lang: LANGUAGES;
  global_setGlobalLang(lang: LANGUAGES): void;
  global_doLogout(local?: boolean, manually?: boolean): Promise<void>;
}

export type IStore = IGlobalSlice & ILoginSlice & IUserSlice & IBindSlice & IEmailBDSlice & IHubspotStateSlice;

export const useStore = create<IStore>()(
  devtools(
    (set, get, ...params) => ({
      global_lang: LANGUAGES.english,
      global_setGlobalLang(lang: LANGUAGES) {
        set(() => ({ global_lang: lang }));
      },
      async global_doLogout(local, manually) {
        get().login_doLogout(local, manually);
        get().user_setUserInfo();
        get().user_setPackageInfo();
        get().user_setBindInfo();
      },
      ...createUserSlice(set, get, ...params),
      ...createLoginSlice(set, get, ...params),
      ...createBindSlice(set, get, ...params),
      ...createEmailBaseDataSlice(set, get, ...params),
      ...createHubspotStateSlice(set, get, ...params),
    }),
    { name: 'leads-navi' }
  )
);
