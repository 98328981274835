import { useCallback } from 'react';
import { message } from 'antd';
import clipboard from 'clipboard';
import { FormattedMessage, useIntl } from 'react-intl';
import { TongyongFuzhi2 } from '@sirius/icons';
import Cov from '@system-global/assets/images/cov.svg?react';
import styles from './styles.module.scss';

const GuidePage = () => {
  const intl = useIntl();

  const copyLink = useCallback(() => {
    clipboard.copy('https://app.leadsnavi.com');
    message.success(intl.formatMessage({ id: '复制成功' }));
  }, [intl]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.cov}>
        <Cov />
      </div>
      <div className={styles.tip}>
        <FormattedMessage id="H5去PC引导" />
      </div>
      <div className={styles.desc}>
        <FormattedMessage
          id="H5APP地址引导"
          values={{
            link: (
              <span className={styles.link} onClick={copyLink}>
                https://app.leadsnavi.com <TongyongFuzhi2 style={{ marginLeft: 4 }} />
              </span>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default GuidePage;
