import React, { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import Logo from '@system-global/assets/images/logo.svg';
import classnames from 'classnames';

interface IProps {
  to?: string;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
}

const AppLogo: React.FC<IProps> = ({ style = {}, to = ROUTE_URLS.default, disabled, className }) => {
  const nav = useNavigate();

  const onClick = () => {
    if (disabled) {
      return;
    }
    if (to.startsWith('http')) {
      window.location.assign(to);
    } else {
      nav(to);
    }
  };

  return (
    <div
      onClick={onClick}
      className={classnames([className, styles.logo])}
      style={{ backgroundImage: `url("${Logo}")`, ...style, cursor: to ? 'pointer' : 'default' }}
    />
  );
};

export default AppLogo;
