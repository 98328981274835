import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKeepNavigate as useNavigateReactRouter } from '@system-global/hooks/use-keep-navigate';
import { NavigateOptions, NavigateFunction } from 'react-router-dom';

export const useLnNavigate = (keepSearch = true): NavigateFunction => {
  const originNav = useNavigate();
  const nav = useNavigateReactRouter();

  const navigate = useCallback(
    (url: string, option?: NavigateOptions) => {
      if (window.location.host !== import.meta.env.SYSTEM_GLOBAL_HOST) {
        window.location.href = `${import.meta.env.SYSTEM_GLOBAL_DOMAIN}${url}`;
        return;
      }
      if (keepSearch) {
        return nav(url, option);
      } else {
        return originNav(url, option);
      }
    },
    [nav, keepSearch, originNav]
  );

  return navigate as NavigateFunction;
};
