import { useLayoutEffect, useContext, useMemo } from 'react';
import { ConfigProvider, App } from 'antd';
import MaskLoading from '@ui/components/mask-loading';
import { StyleProvider } from '@ant-design/cssinjs';
import { useInterceptState, InterceptState } from './hooks/useInterceptState';
import Header from './components/header';
import GuidePage from './module/guide';
import SendPage from './module/send';
import BindSite from './module/bind';
import styles from './styles.module.scss';

const MobileIntercept = () => {
  const { state, setState } = useInterceptState();

  // 给message等静态方法触发的通知加前缀样式
  const { locale, theme } = useContext(ConfigProvider.ConfigContext);

  const content = useMemo(() => {
    if (state === InterceptState.Loading || state === InterceptState.NotLogin) {
      return <MaskLoading mask={false} />;
    }

    if (state === InterceptState.BindSite) {
      return <BindSite onClose={() => setState(InterceptState.SendEmail)} />;
    }

    if (state === InterceptState.SendEmail) {
      return (
        <div className={styles.wrapper}>
          <SendPage onClose={() => setState(InterceptState.PCGuideView)} />
        </div>
      );
    }

    return <GuidePage />;
  }, [state, setState]);

  useLayoutEffect(() => {
    ConfigProvider.config({
      holderRender: children => (
        <StyleProvider hashPriority="high">
          <ConfigProvider prefixCls="mobile-layout" iconPrefixCls="icon" locale={locale} theme={theme}>
            <App message={{ maxCount: 1 }} notification={{ maxCount: 1 }}>
              {children}
            </App>
          </ConfigProvider>
        </StyleProvider>
      ),
    });
  }, [locale, theme]);

  return (
    <div className={styles.wrapper}>
      <Header />
      {content}
    </div>
  );
};

export default MobileIntercept;
