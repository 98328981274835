import { PropsWithChildren, useCallback } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import styles from './style.module.scss';

interface Props extends PropsWithChildren {
  className?: string;
  mask?: boolean;
  showClose?: boolean;
  onClose?: () => void;
}

const MaskLoading = (props: Props) => {
  const { className, mask, showClose, onClose } = props;

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  return (
    <div className={classnames(className, styles.wrapper)}>
      {showClose && (
        <div className={styles.close} onClick={handleClose}>
          <CloseOutlined />
        </div>
      )}
      {mask && <div className={styles.loadingMask}></div>}
      <div className={styles.loadingContent}>
        <div className={styles.payLoading}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={styles.addition}>{props.children}</div>
      </div>
    </div>
  );
};

export default MaskLoading;
