import React, { useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button } from 'antd';
import { TongyongJiantou2Zuo } from '@sirius/icons';
import RequiredFormItem from '@system-global/components/login-and-register-form/pwd-form-item-package/required-form-item';
import { IApiEmailSignupReq } from '@api/system-global/__generate__/default/models';

interface ICompProps {
  registerInfo: IApiEmailSignupReq;
  loading: boolean;
  onSubmit: (params: IAccountFieldType) => void;
  onGoBack: () => void;
}

export interface IAccountFieldType {
  companyName: string;
  firstName: string;
  lastName: string;
  website: string;
}

const AccountForm: React.FC<ICompProps> = ({ onSubmit, onGoBack, loading, registerInfo }) => {
  const intl = useIntl();
  const [form] = Form.useForm<IAccountFieldType>();

  const info = useMemo(
    () => ({
      companyName: registerInfo.companyName,
      firstName: registerInfo.firstName,
      lastName: registerInfo.lastName,
      website: registerInfo.website,
    }),
    [registerInfo]
  );

  useEffect(() => {
    form.setFieldsValue({
      ...info,
    });
  }, [form, info]);

  const onNext = () => {
    form.validateFields().then(() => {
      const { companyName, firstName, lastName, website } = form.getFieldsValue();
      onSubmit({ companyName, firstName, lastName, website });
    });
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        <FormattedMessage id="创造你的账户" />
      </p>
      <p className={styles.subTitle}>
        <span>
          <FormattedMessage id="距离获得线索只有一步之遥" />
        </span>
      </p>
      <Form name="passwordLogin" form={form} layout="vertical" className={styles.loginForm}>
        <div className={styles.inlineItemContainer}>
          <RequiredFormItem
            name="firstName"
            className={styles.noWrapItem}
            field={intl.formatMessage({ id: '姓' })}
            onPressEnter={onNext}
          />
          <RequiredFormItem
            name="lastName"
            className={styles.noWrapItem}
            field={intl.formatMessage({ id: '名' })}
            onPressEnter={onNext}
          />
        </div>
        <RequiredFormItem name="companyName" field={intl.formatMessage({ id: '公司名称' })} onPressEnter={onNext} />
        <RequiredFormItem
          name="website"
          field={intl.formatMessage({ id: '网址' })}
          addonBefore="http(s)://"
          onPressEnter={onNext}
        />
      </Form>
      <Button
        id="registerPageCreateSubmit"
        type="primary"
        size="large"
        className={styles.submitBtn}
        onClick={onNext}
        loading={loading}
      >
        <FormattedMessage id="创建账号" />
      </Button>
      <div className={styles.goBack} onClick={onGoBack}>
        <TongyongJiantou2Zuo style={{ fontSize: 16 }} />
        <span>
          <FormattedMessage id="后退" />
        </span>
      </div>
    </div>
  );
};

export default AccountForm;
