import React, { useState } from 'react';
import PasswordFormItem from './password-form-item';
import PasswordFormItemCheckerGroup from './password-form-item-checker-group';
import PasswordRepeatFormItem from '@system-global/components/login-and-register-form/pwd-form-item-package/password-repeat-form-item';
import styles from './styles.module.scss';

interface ICompProps {
  value: string;
  label?: string;
  showRepeat?: boolean;
  showChecker?: boolean;
  onPressEnter?: () => void;
}

const PwdFormItemPackage: React.FC<ICompProps> = ({ value, label, showRepeat, showChecker = true, onPressEnter }) => {
  const [checkerVisible, setCheckerVisible] = useState(false);

  if (!showRepeat) {
    // 不展示重复输入时  提示信息改为popover
    return (
      <>
        {showChecker && checkerVisible && (
          <div className={styles.checker}>
            <PasswordFormItemCheckerGroup value={value} />
          </div>
        )}
        <PasswordFormItem
          label={label}
          onPressEnter={onPressEnter}
          onFocus={() => setCheckerVisible(true)}
          onBlur={() => setCheckerVisible(false)}
        />
      </>
    );
  }

  return (
    <>
      <PasswordFormItem label={label} onPressEnter={onPressEnter} />
      {showRepeat && <PasswordRepeatFormItem lastPwd={value} onPressEnter={onPressEnter} />}
      {showChecker && <PasswordFormItemCheckerGroup value={value} />}
    </>
  );
};

export default PwdFormItemPackage;
