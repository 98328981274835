/** 教程引导相关 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 教程引导相关 预览邮件正文（绑定站点教程） */
export async function bindSitePreviewEmailGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultPreviewEmailResp>({
    url: `/api/biz/tutorial/bindSite/previewEmail`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 教程引导相关 仅标记教程已发送（绑定站点教程） */
export async function bindSiteRecordOnlyPost(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultstring>({
    url: `/api/biz/tutorial/bindSite/recordOnly`,
    method: 'post',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 教程引导相关 发送教程邮件并标记教程已发送（绑定站点教程） */
export async function bindSiteSendAndRecordPost(payload: model.IApiTutorialSendReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultstring>({
    url: `/api/biz/tutorial/bindSite/sendAndRecord`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 教程引导相关 仅发送教程邮件（绑定站点教程） */
export async function bindSiteSendOnlyPost(payload: model.IApiTutorialSendReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultstring>({
    url: `/api/biz/tutorial/bindSite/sendOnly`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 教程引导相关 获取教程发送状态（绑定站点教程） */
export async function bindSiteSendStateGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultTutorialSendState>({
    url: `/api/biz/tutorial/bindSite/sendState`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
