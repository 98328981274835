import { Form, Button, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStore } from '@system-global/store';
import { TongyongGuanbiXian } from '@sirius/icons';
import { useBindForm } from '../../hooks/useBindForm';
import styles from './styles.module.scss';

interface Props {
  onClose?: () => void;
}
const BindSite = (props: Props) => {
  const { onClose } = props;
  const userEmail = useStore(state => state?.user_userInfo?.email);
  const userInfo = useStore(store => store.user_userInfo);
  const intl = useIntl();
  const { loading, form, submit } = useBindForm(onClose);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.title}>
          <FormattedMessage id="H5绑定网站标题" />
        </div>
        <div className={styles.desc}>
          <FormattedMessage
            id="H5绑定网站描述"
            values={{ email: <span className={styles.email}>{userEmail || ''}</span> }}
          />
        </div>
      </div>

      <div className={styles.formPanel}>
        <Form
          form={form}
          initialValues={{
            name: userInfo?.companyName,
            website: userInfo?.website,
          }}
        >
          <Form.Item
            label={<FormattedMessage id="绑定网站公司名称" />}
            name="name"
            rules={[{ required: true, message: intl.formatMessage({ id: '请输入公司名称' }) }]}
          >
            <Input
              className={styles.input}
              size="large"
              placeholder={intl.formatMessage({ id: '请输入公司名称' })}
              allowClear={{ clearIcon: <TongyongGuanbiXian style={{ fontSize: 16 }} /> }}
            />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id="绑定网站网站地址" />}
            name="website"
            rules={[
              { required: true, message: intl.formatMessage({ id: 'H5绑定网站网址格式错误' }) },
              {
                pattern: /^([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/,
                message: intl.formatMessage({ id: 'H5绑定网站网址格式错误' }),
              },
            ]}
          >
            <Input
              className={styles.input}
              size="large"
              addonBefore="https://"
              placeholder={intl.formatMessage({ id: 'H5绑定网站网址Placeholder' })}
              allowClear={{ clearIcon: <TongyongGuanbiXian style={{ fontSize: 16 }} /> }}
            />
          </Form.Item>
        </Form>
      </div>
      <div className={styles.operation}>
        <Button className={styles.btn} type="primary" block loading={loading} onClick={submit}>
          <FormattedMessage id="H5绑定网站提交" />
        </Button>
      </div>
    </div>
  );
};

export default BindSite;
