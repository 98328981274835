/**
 * 跳转时，保留当前页面query参数。如果跳转的目标链接本身带相同的query参数，则目标链接的参数覆盖当前页面的参数。
 */
import { useCallback } from 'react';
import { useNavigate, NavigateOptions, NavigateFunction, useLocation } from 'react-router-dom';

const mergeSearchParams = (target: string, append: string): string => {
  const targetParams = new URLSearchParams(target);
  const appendParams = new URLSearchParams(append);

  // 合并新的查询参数
  Object.keys(appendParams).forEach(key => {
    targetParams.set(key, appendParams.get(key) || '');
  });

  return targetParams.toString();
};

export const useKeepNavigate = (): NavigateFunction => {
  const nav = useNavigate();
  const location = useLocation();

  const navigate = useCallback(
    (url: string, option?: NavigateOptions): void => {
      let search = location?.search || '';
      let newUrl = url;
      if (url.includes('?')) {
        const [_url, targetUrlSearch] = url.split('?');
        newUrl = _url;
        search = mergeSearchParams(search, targetUrlSearch);
      }
      if (!search.startsWith('?')) {
        search = '?' + search;
      }
      return nav(`${newUrl}${search}`, option);
    },
    [nav, location]
  );

  return navigate as NavigateFunction;
};
