import { Outlet, useMatches } from 'react-router-dom';
import { Layout } from 'antd';
import AppHeader from '@system-global/components/app-header';
import { IRouteConfig } from '@system-global/config/types';
import styles from './styles.module.scss';
import { Suspense } from 'react';

export function PaymentRoot() {
  const matches = useMatches();
  const hideHeader = matches.some(v => (v.handle as IRouteConfig)?.hideHeader);

  return (
    <Layout className={styles.wrapper}>
      {!hideHeader ? <AppHeader className={styles.header} /> : null}
      <Layout.Content className={styles.content}>
        <Suspense>
          <Outlet />
        </Suspense>
      </Layout.Content>
    </Layout>
  );
}

export default PaymentRoot;
